import React from "react";

function NoDataScreen({ icon, content, button }) {
  return (
    <div className="flex flex-col justify-center items-center w-full py-1">
      <div>{icon}</div>
      <div className="mt-05 mb-075 flex flex-col items-center">{content}</div>
      {button}
    </div>
  );
}

export default NoDataScreen;
