import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";

import Chip from "components/common/Chip";
import Button from "components/common/Button";
import PageLoader from "components/common/PageLoader";
import { convertDate, getValue } from "components/Screens/CommonUtils/index";
import UnassignedEmployeeModal from "components/Screens/Workforce/UserDetails/NewPersonalInfo/EmploymentInfo/UnassignEmployee";
import AssignToCustomerModal from "components/Screens/Workforce/UserDetails/NewPersonalInfo/EmploymentInfo/AssignToCustomer";
import NoDataScreen from "components/Widgets/NoDataScreen";
import CustomTooltip from "components/common/Tooltip";

import {
  PersonOffOutlined as PersonOffOutlinedIcon,
  InfoOutlined as InfoOutlinedIcon,
  AccessTime as AccessTimeIcon,
  EditOutlined as EditOutlinedIcon,
  WorkOutline as WorkOutlineIcon,
} from "@mui/icons-material";

import "./EmploymentInfo.scss";

function UnAssignedEmployee({ onAssignToCustomerBtnClick }) {
  return (
    <div className="unassigned-employee-container">
      <WorkOutlineIcon />
      <div className="para-body-m-regular mb-05">This employee is not currently assigned to a customer.</div>
      <Button label="Assign to Customer" size="small" onClick={onAssignToCustomerBtnClick} variant="outlined" />
    </div>
  );
}

function CustomTooltipContent({ reason }) {
  const { reasonDate, reason: reasonMsg, comments } = reason || {};

  return (
    <div className="space-y-2">
      <div>
        <p className="para-body-xs-regular">Inactive since</p>
        <p className="para-body-xs-semibold">{convertDate(reasonDate)}</p>
      </div>
      <div>
        <p className="para-body-xs-regular">Reason for marking Inactive</p>
        <p className="para-body-xs-semibold">{getValue(reasonMsg)}</p>
      </div>
      <div>
        <p className="para-body-xs-regular">Additional comments</p>
        <p className="para-body-xs-semibold">{getValue(comments)}</p>
      </div>
    </div>
  );
}

function NoDataScreenContent({ reason }) {
  return (
    <div className="flex items-center">
      <p className="heading-h5-semibold pr-025">This employee is marked as</p>
      <CustomTooltip direction="right" content={<CustomTooltipContent reason={reason} />}>
        <div className="cursor-pointer">
          <Chip
            label="Inactive"
            startIcon={<InfoOutlinedIcon color="#000" />}
            color="inherit-text"
            size="small"
            variant="contained"
          />
        </div>
      </CustomTooltip>
    </div>
  );
}

function EmploymentInfo({ initialPersonalDataEmployment, employeeDetails, fetchEmployeeDetailsByIDV2 }) {
  const { capturingReasonsForInactiveEmployees } = useFlags();

  const rowClassesForPersonalInfo = "flex justify-between w-100p mb-1";

  const [showAssignToCustomerModal, setShowAssignToCustomerModal] = useState(false);
  const [showAssignToCustomerModalForEdit, setShowAssignToCustomerModalForEdit] = useState(false);
  const [showUnassignmentModal, setShowUnassignmentModal] = useState(false);

  const loadingGetEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);
  const { companyName, addressLine1, city, state, country, zip, department, designation, employmentType, joiningDate } =
    employeeDetails?.customerInfo || {};
  const { startDate, status } = employeeDetails || {};
  const { firstName, middleName, lastName } =
    useSelector((s) => s.GetEmployeeDetails?.getEmployeeDetails)?.payload || {};

  const isEmployeePending = status === "pending";

  const renderContent = () => {
    if (status === "unassigned") {
      return <UnAssignedEmployee onAssignToCustomerBtnClick={() => setShowAssignToCustomerModal(true)} />;
    }

    if (status === "inactive" && capturingReasonsForInactiveEmployees) {
      return (
        <div className="no-screen-wrapper">
          <NoDataScreen
            icon={
              <div className="no-screen-inactive-icon">
                <PersonOffOutlinedIcon sx={{ fontSize: 20, color: "#AFAFAF" }} />
              </div>
            }
            content={<NoDataScreenContent reason={employeeDetails?.reason} />}
          />
        </div>
      );
    }

    return (
      <Grid item container direction="column" rowSpacing={1.5} xs={12}>
        <div className={rowClassesForPersonalInfo}>
          <div className="heading-h5-regular">Company Name</div>
          <div className="para-body-m-semibold">{getValue(companyName)}</div>
        </div>

        <div className={rowClassesForPersonalInfo}>
          <div className="heading-h5-regular">Department</div>
          <div className="para-body-m-semibold">{getValue(department)}</div>
        </div>

        <div className={rowClassesForPersonalInfo}>
          <div className="heading-h5-regular">Job Title</div>
          <div className="para-body-m-semibold">{getValue(designation)}</div>
        </div>

        <div className={rowClassesForPersonalInfo}>
          <div className="heading-h5-regular">Hired Date</div>
          {isEmployeePending ? (
            <Chip
              variant="filled"
              size="small"
              color="warning"
              label={`Scheduled for ${convertDate(joiningDate)}`}
              startIcon={<AccessTimeIcon />}
            />
          ) : (
            <div className="para-body-m-semibold">{convertDate(joiningDate)}</div>
          )}
        </div>

        <div className={rowClassesForPersonalInfo}>
          <div className="heading-h5-regular">Company Address</div>
          <div className="para-body-m-semibold">
            {getValue(`${addressLine1 || ""} ${city || ""} ${state || ""} ${country || ""} ${zip || ""} `)}
          </div>
        </div>
      </Grid>
    );
  };

  return (
    <>
      {loadingGetEmployeeDetails && <PageLoader />}

      <AssignToCustomerModal
        employeeId={initialPersonalDataEmployment?.employeeId}
        open={showAssignToCustomerModal || showAssignToCustomerModalForEdit}
        showAssignToCustomerModalForEdit={showAssignToCustomerModalForEdit}
        employmentStatus={status}
        initialEmploymentDetails={
          showAssignToCustomerModalForEdit
            ? {
                employmentType,
                dateOfJoining: startDate,
                jobTitle: designation,
                department,
                company: companyName,
              }
            : {}
        }
        fetchEmployeeDetailsByIDV2={fetchEmployeeDetailsByIDV2}
        closeModal={() => {
          setShowAssignToCustomerModal(false);
          setShowAssignToCustomerModalForEdit(false);
        }}
      />

      <UnassignedEmployeeModal
        fetchEmployeeDetailsByIDV2={fetchEmployeeDetailsByIDV2}
        employeeId={initialPersonalDataEmployment?.employeeId}
        companyName={companyName}
        name={`${firstName} ${middleName || ""} ${lastName}`}
        open={showUnassignmentModal}
        closeModal={() => setShowUnassignmentModal(false)}
      />

      <div className={rowClassesForPersonalInfo.replace("mb-1", "mb-15")}>
        <div className="flex items-center gap-05">
          <WorkOutlineIcon className="color-icon-primary admin-overview-heading-icon" alt="work" />
          <div className="heading-h4-semibold">Employment Info</div>
        </div>

        {status !== "unassigned" && status !== "inactive" && (
          <div className="flex gap-1">
            <Button
              label="Un-assign From Customer"
              size="small"
              onClick={() => setShowUnassignmentModal(true)}
              variant="outlined"
            />
            <Button
              label="Edit"
              size="small"
              onClick={() => setShowAssignToCustomerModalForEdit(true)}
              variant="outlined"
              startIcon={<EditOutlinedIcon />}
            />
          </div>
        )}
      </div>
      {renderContent()}
    </>
  );
}

export default EmploymentInfo;
