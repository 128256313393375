import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { LinearProgress } from "@mui/material";

import {
  UploadFile as UploadFileIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  PictureAsPdfOutlined as PictureAsPdfOutlinedIcon,
  RefreshOutlined as RefreshOutlinedIcon,
} from "@mui/icons-material";

import { getFileSizeToDisplay, pluralize } from "util/utils";

import "./drag-drop.scss";

const calculateStatsForFiles = (files) => {
  return {
    uploaded: files.filter((file) => file.status === "complete").length || false,
    uploading: files.filter((file) => file.status == "uploading").length || false,
    failed: files.filter((file) => file.status === "failed").length || false,
  };
};

function getFileStatus({ error, size, progress, loading, bambooHrBulkUpload }) {
  if (error) {
    return (
      <>
        <span className="file-info-error">{error}</span>
        <span className="dot-span file-info-error"> • </span>
        <span className="file-info-error">Failed</span>
      </>
    );
  }
  return (
    <>
      <span className="file-size">{getFileSizeToDisplay(size)}</span>
      <span className="dot-span"> • </span>
      {bambooHrBulkUpload ? (
        <span className="file-info-complete">{progress != 100 ? "Uploading" : "Complete"}</span>
      ) : (
        <span className="file-info-complete">{loading ? "Uploading" : "Complete"}</span>
      )}
    </>
  );
}

function renderStatus(files) {
  const { uploaded, uploading, failed } = calculateStatsForFiles(files);

  return (
    <div className="flex para-body-m-medium py-1">
      {uploaded && (
        <span>
          {uploaded} {pluralize("item", uploaded)} uploaded&nbsp;
        </span>
      )}
      {uploaded && (uploading || failed) && <>•&nbsp;</>}
      {uploading && (
        <span>
          Uploading {uploading} {pluralize("item", uploading)}&nbsp;
        </span>
      )}
      {uploading && failed && <>•&nbsp;</>}{" "}
      {failed && (
        <span className="file-status-error">
          {failed} {pluralize("upload", failed)} failed{" "}
        </span>
      )}
    </div>
  );
}

function SelectedFile({
  files = [],
  onFileDelete,
  showGreenTick,
  uploadProgress,
  isMultiFileSupported,
  onFileSelect,
  loading,
  showButtons = true,
}) {
  const { bambooHrBulkUpload } = useFlags();

  return (
    <>
      {bambooHrBulkUpload && isMultiFileSupported && files.length > 0 && (
        <> {renderStatus(files, bambooHrBulkUpload)}</>
      )}
      <div className={`uploader-files ${isMultiFileSupported && files.length > 0 && "uploader-files-border"}`}>
        {files.map((file) => {
          const { id, fileName, error, size, progress = 0, retry = false } = file;
          return (
            <div className="file-item" key={id}>
              <div className={error ? "file-icon-red" : "file-icon"}>
                {isMultiFileSupported ? <PictureAsPdfOutlinedIcon /> : <UploadFileIcon />}
              </div>
              <div className="file-details">
                <div className="file-name-success">{fileName}</div>
                <div className="file-size-status">
                  {getFileStatus({ error, size, progress, loading, bambooHrBulkUpload })}
                </div>
                {(error || (bambooHrBulkUpload ? progress != 100 : loading)) && (
                  <LinearProgress
                    classes={{
                      root: `${error && "progress-root-error"} custom-linear-progress`,
                      bar: error ? "progress-bar-error" : "progress-bar-complete",
                    }}
                    variant="determinate"
                    value={bambooHrBulkUpload ? progress : uploadProgress}
                  />
                )}
              </div>

              {showButtons && (
                <div className="file-remove">
                  {progress != 100 && !retry && bambooHrBulkUpload && <CloseIcon onClick={() => onFileDelete(id)} />}
                  {retry && (
                    <RefreshOutlinedIcon
                      onClick={() => {
                        onFileSelect([file.file]);
                      }}
                    />
                  )}
                  {bambooHrBulkUpload ? (
                    <>
                      {progress == 100 && !retry && <DeleteIcon onClick={() => onFileDelete(id)} />}
                      {progress == 100 && !error && <CheckCircleIcon className="check-circle ml-075" />}
                    </>
                  ) : (
                    <>
                      <DeleteIcon onClick={() => onFileDelete(id)} />
                      {showGreenTick && !error && <CheckCircleIcon className="check-circle ml-075" />}
                    </>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

export default SelectedFile;
